var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AdvertisMgrForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.backData.status !== 0,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              !_vm.id
                ? _c(
                    "col2-block",
                    { attrs: { title: "服务信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "广告页标题",
                            rules: [
                              {
                                required: true,
                                message: "请输入广告页标题",
                                trigger: "blur",
                              },
                            ],
                            prop: "title",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入1-30字的标题描述",
                              width: 300,
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属App",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属App",
                                trigger: "change",
                              },
                            ],
                            prop: "type",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.typeOps, width: _vm.width },
                            on: { change: _vm.changeAppType },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上传广告页",
                            rules: [
                              {
                                required: true,
                                message: "请上传图片",
                                trigger: "change",
                              },
                            ],
                            prop: "smallPic",
                          },
                        },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.form.smallPic,
                              fileWidth: 160,
                              fileHeight: 284,
                              imageWH: [720, 1280],
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.form, "smallPic", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.form, "smallPic", $event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tip",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          "注：建议宽720px高1280px，图片小于512KB"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2245128733
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上传广告页(全面屏)",
                            rules: [
                              {
                                required: true,
                                message: "请上传图片",
                                trigger: "change",
                              },
                            ],
                            prop: "smallPic",
                          },
                        },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.form.fullScreenPic,
                              fileWidth: 160,
                              fileHeight: 284,
                              imageWH: [828, 1792],
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "fullScreenPic",
                                  $event
                                )
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "fullScreenPic",
                                  $event
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tip",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          "注：建议宽828px高1792px，图片小于512KB"
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2218342044
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转类型", required: "" } },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              value: _vm.form.focusType,
                              options: _vm.skipTypeOps,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "focusType", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.focusType === 1
                        ? _c(
                            "div",
                            { staticClass: "el-form-div-wrapper" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "内容",
                                    prop: "content",
                                    rules: [
                                      {
                                        required: true,
                                        message: "输入内容不能为空",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-ueditor", {
                                    model: {
                                      value: _vm.form.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "content", $$v)
                                      },
                                      expression: "form.content",
                                    },
                                  }),
                                  _vm.form.type === 12
                                    ? _c(
                                        "div",
                                        { staticClass: "choose-jump-wrapper" },
                                        [
                                          _c("jump-go", {
                                            on: { callback: _vm.jumpCallback },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.focusType === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "跳转页面",
                                    prop: "skipData",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择跳转页面",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("jump-go", {
                                    attrs: {
                                      btnText: _vm.jumpName,
                                      skipType: 2,
                                    },
                                    on: { callback: _vm.jumpFunCallback },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.focusType === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "外部链接",
                                prop: "url",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入外部链接",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-wrapper" },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      placeholder: "外部链接URL地址",
                                      width: _vm.width,
                                      maxlength: 200,
                                    },
                                    model: {
                                      value: _vm.form.url,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "url", $$v)
                                      },
                                      expression: "form.url",
                                    },
                                  }),
                                  _c("span", { staticClass: "right-tip" }, [
                                    _vm._v(
                                      '注：请填写以"http://"或"https://"开头的URL地址 '
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发布项目",
                            rules: [
                              {
                                required: true,
                                message: "请输入发布项目",
                                trigger: "change",
                                type: "array",
                              },
                            ],
                            prop: "communityIds",
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            attrs: { list: _vm.form.communityIds },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "communityIds",
                                  $event
                                )
                              },
                              select: _vm.toSelectCommunity,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上线时间",
                            rules: [
                              {
                                required: true,
                                message: "请输入上线时间",
                                trigger: "blur",
                              },
                            ],
                            prop: "onlineFromDate",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              minuteStep: 10,
                              type: "datetime",
                              minDate: new Date(),
                              maxDate: _vm.form.onlineToDate,
                            },
                            model: {
                              value: _vm.form.onlineFromDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "onlineFromDate", $$v)
                              },
                              expression: "form.onlineFromDate",
                            },
                          }),
                          _vm._v("    注：时间间隔可设为10分钟\n        "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "下线时间",
                            rules: [
                              {
                                required: true,
                                message: "请输入下线时间",
                                trigger: "blur",
                              },
                            ],
                            prop: "onlineToDate",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              minuteStep: 10,
                              type: "datetime",
                              minDate: _vm.form.onlineFromDate || new Date(),
                            },
                            model: {
                              value: _vm.form.onlineToDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "onlineToDate", $$v)
                              },
                              expression: "form.onlineToDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.id
                ? _c(
                    "col2-block",
                    { attrs: { title: "服务信息" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.title) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属App", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.typeName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传广告页", required: "" } },
                        [
                          _c("img", {
                            attrs: {
                              width: "156px",
                              height: "284px",
                              src: _vm.backData.smallPic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toPreview(_vm.backData.smallPic)
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "上传广告页(全面屏)", required: "" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "156px",
                              height: "284px",
                              src: _vm.backData.fullScreenPic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toPreview(_vm.backData.fullScreenPic)
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发布项目", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backCommunitys) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转类型", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.focusTypeName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm.backData.focusType === "1"
                        ? _c(
                            "div",
                            { staticClass: "el-form-div-wrapper" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "内容", required: "" } },
                                [
                                  _c("v-ueditor", {
                                    model: {
                                      value: _vm.backData.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.backData, "content", $$v)
                                      },
                                      expression: "backData.content",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.backData.focusType === "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "跳转页面" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("skipname")(
                                          _vm.backData.skipData
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.backData.focusType === "3"
                        ? _c("el-form-item", { attrs: { label: "外部链接" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.backData.url) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上线时间", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.onlineFromDate) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "下线时间", required: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.onlineToDate) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.id && _vm.backData.status === 1
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                            prop: "status",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              title: "项目列表",
              isShow: _vm.communityMultiShow,
              searchParams: _vm.communitySearchParams,
              extraParams: _vm.grantExtraParams,
              backFill: _vm.form.communityIds,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.communityMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "communityIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "communityIds", $event)
              },
              callback: _vm.communityIdChange,
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "项目名称" },
                      model: {
                        value: _vm.communitySearchParams.communityName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityName",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "区域类型", options: _vm.regionTypeOps },
                      model: {
                        value: _vm.communitySearchParams.searchRegionType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "searchRegionType",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.searchRegionType",
                      },
                    }),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            label: "所属区域",
                            subjoin: {
                              regionType:
                                _vm.communitySearchParams.searchRegionType,
                            },
                          },
                          model: {
                            value: _vm.communitySearchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "regionId",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.allRegionParams,
                        false
                      )
                    ),
                    _c("v-input", {
                      attrs: { label: "所在省" },
                      model: {
                        value: _vm.communitySearchParams.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "province", $$v)
                        },
                        expression: "communitySearchParams.province",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在城市" },
                      model: {
                        value: _vm.communitySearchParams.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "city", $$v)
                        },
                        expression: "communitySearchParams.city",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "所在区" },
                      model: {
                        value: _vm.communitySearchParams.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.communitySearchParams, "area", $$v)
                        },
                        expression: "communitySearchParams.area",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目阶段状态",
                        options: _vm.communityStageOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityStage,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityStage",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityStage",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目类型",
                        options: _vm.communityTypeOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityType",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityType",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "项目状态",
                        options: _vm.communityStatusOps,
                      },
                      model: {
                        value: _vm.communitySearchParams.communityStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.communitySearchParams,
                            "communityStatus",
                            $$v
                          )
                        },
                        expression: "communitySearchParams.communityStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.communityMultiParams,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }