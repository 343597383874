<template>
  <div class="AdvertisMgrForm-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                :footerShow="backData.status !== 0"
                @update="update">
      <col2-detail>
        <col2-block title="服务信息"
                    v-if="!id">
          <el-form-item label="广告页标题"
                        :rules="[{ required: true, message: '请输入广告页标题', trigger: 'blur' }]"
                        prop="title">
            <v-input v-model="form.title"
                     placeholder="请输入1-30字的标题描述"
                     :width="300" />
          </el-form-item>
          <el-form-item label="所属App"
                        :rules="[{ required: true, message: '请选择所属App', trigger: 'change' }]"
                        prop="type">
            <v-select v-model="form.type"
                      :options="typeOps"
                      :width="width"
                      @change="changeAppType" />
          </el-form-item>
          <el-form-item label="上传广告页"
                        :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]"
                        prop="smallPic">
            <v-uploader :action="uploadURL"
                        :imgUrls.sync="form.smallPic"
                        :fileWidth="160"
                        :fileHeight="284"
                        :imageWH="[720, 1280]">
              <template #tip>
                <span>注：建议宽720px高1280px，图片小于512KB</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="上传广告页(全面屏)"
                        :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]"
                        prop="smallPic">
            <v-uploader :action="uploadURL"
                        :imgUrls.sync="form.fullScreenPic"
                        :fileWidth="160"
                        :fileHeight="284"
                        :imageWH="[828, 1792]">
              <template #tip>
                <span>注：建议宽828px高1792px，图片小于512KB</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="跳转类型"
                        required>
            <checkbox-plus :value.sync="form.focusType"
                           :options="skipTypeOps" />
          </el-form-item>
          <div class="el-form-div-wrapper"
               v-if="form.focusType === 1">
            <el-form-item label="内容"
                          prop="content"
                          :rules="[ { required: true, message: '输入内容不能为空', trigger: 'change' } ]">
              <v-ueditor v-model="form.content"></v-ueditor>
              <div v-if="form.type === 12"
                   class="choose-jump-wrapper">
                <jump-go @callback="jumpCallback"></jump-go>
              </div>
            </el-form-item>
          </div>
          <div v-if="form.focusType === 2">
            <el-form-item label="跳转页面"
                          prop="skipData"
                          :rules="[{ required: true, message: '请选择跳转页面', trigger: 'change' }]">
              <jump-go :btnText="jumpName"
                       :skipType="2"
                       @callback="jumpFunCallback"></jump-go>
            </el-form-item>
          </div>
          <el-form-item v-if="form.focusType === 3"
                        label="外部链接"
                        prop="url"
                        :rules="[{ required: true, message: '请输入外部链接', trigger: 'blur' }]">
            <div class="content-wrapper">
              <v-input v-model="form.url"
                       placeholder="外部链接URL地址"
                       :width="width"
                       :maxlength="200"></v-input>
              <span class="right-tip">注：请填写以"http://"或"https://"开头的URL地址 </span>
            </div>
          </el-form-item>
          <el-form-item label="发布项目"
                        :rules="[{ required: true, message: '请输入发布项目', trigger: 'change', type: 'array' }]"
                        prop="communityIds">
            <chosenListPanel :list.sync="form.communityIds"
                             @select="toSelectCommunity"></chosenListPanel>
          </el-form-item>
          <el-form-item label="上线时间"
                        :rules="[{ required: true, message: '请输入上线时间', trigger: 'blur' }]"
                        prop="onlineFromDate">
            <v-datepicker :minuteStep="10"
                          v-model="form.onlineFromDate"
                          type="datetime"
                          :minDate="new Date()"
                          :maxDate="form.onlineToDate" /> &nbsp;&nbsp;&nbsp;注：时间间隔可设为10分钟
          </el-form-item>
          <el-form-item label="下线时间"
                        :rules="[{ required: true, message: '请输入下线时间', trigger: 'blur' }]"
                        prop="onlineToDate">
            <v-datepicker :minuteStep="10"
                          v-model="form.onlineToDate"
                          type="datetime"
                          :minDate="form.onlineFromDate || new Date()" />
          </el-form-item>
        </col2-block>
        <col2-block title="服务信息"
                    v-if="!!id">
          <el-form-item label="名称"
                        required>
            {{backData.title}}
          </el-form-item>
          <el-form-item label="所属App"
                        required>
            {{backData.typeName}}
          </el-form-item>
          <el-form-item label="上传广告页"
                        required>
            <img width="156px"
                 height="284px"
                 :src="backData.smallPic"
                 @click="toPreview(backData.smallPic)" />
          </el-form-item>
          <el-form-item label="上传广告页(全面屏)"
                        required>
            <img width="156px"
                 height="284px"
                 :src="backData.fullScreenPic"
                 @click="toPreview(backData.fullScreenPic)" />
          </el-form-item>
          <el-form-item label="发布项目"
                        required>
            {{backCommunitys}}
          </el-form-item>
          <el-form-item label="跳转类型"
                        required>
            {{backData.focusTypeName}}
          </el-form-item>
          <div class="el-form-div-wrapper"
               v-if="backData.focusType === '1'">
            <el-form-item label="内容"
                          required>
              <v-ueditor v-model="backData.content"></v-ueditor>
            </el-form-item>
          </div>
          <div v-if="backData.focusType === '2'">
            <el-form-item label="跳转页面">
              {{backData.skipData | skipname}}
            </el-form-item>
          </div>
          <el-form-item v-if="backData.focusType === '3'"
                        label="外部链接">
            {{backData.url}}
          </el-form-item>
          <el-form-item label="上线时间"
                        required>
            {{backData.onlineFromDate}}
          </el-form-item>
          <el-form-item label="下线时间"
                        required>
            {{backData.onlineToDate}}
          </el-form-item>
        </col2-block>
        <col2-block title="操作信息"
                    v-if="!!id && backData.status === 1">
          <el-form-item label="状态"
                        :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]"
                        prop="status">
            <v-select v-model="form.status"
                      :options="statusOps"
                      :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <!-- 发布项目 multi-select begin -->
    <multi-select title="项目列表"
                  :isShow.sync="communityMultiShow"
                  :searchParams="communitySearchParams"
                  v-bind="communityMultiParams"
                  :extraParams="grantExtraParams"
                  :backFill.sync="form.communityIds"
                  @callback="communityIdChange">
      <template #searchSlot>
        <v-input label="项目名称"
                 v-model="communitySearchParams.communityName"></v-input>
        <v-select label="区域类型"
                  v-model="communitySearchParams.searchRegionType"
                  :options="regionTypeOps"></v-select>
        <v-select2 label="所属区域"
                   v-model="communitySearchParams.regionId"
                   v-bind="allRegionParams"
                   :subjoin="{regionType : communitySearchParams.searchRegionType}"></v-select2>
        <v-input label="所在省"
                 v-model="communitySearchParams.province"></v-input>
        <v-input label="所在城市"
                 v-model="communitySearchParams.city"></v-input>
        <v-input label="所在区"
                 v-model="communitySearchParams.area"></v-input>
        <v-select label="项目阶段状态"
                  v-model="communitySearchParams.communityStage"
                  :options="communityStageOps"></v-select>
        <v-select label="项目类型"
                  v-model="communitySearchParams.communityType"
                  :options="communityTypeOps"></v-select>
        <v-select label="项目状态"
                  v-model="communitySearchParams.communityStatus"
                  :options="communityStatusOps"></v-select>
      </template>
    </multi-select>
    <!-- 发布项目 项目 multi-select end -->
  </div>
</template>
<script>
import {
  createURL,
  editURL,
  queryURL,
  uploadURL,
  getCommunityListURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL
} from './api'
import {
  setTypeOps,
  setStatusOps,
  regionTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  jumpGoCodeMap
} from './map'
import { vUploader, vUeditor } from 'components/control'
import { Col2Detail, Col2Block, MultiSelect, ChosenListPanel, CheckboxPlus, JumpGo } from 'components/bussiness'
import {
  allRegionParams
} from 'common/select2Params'
import { jumpGoCode } from 'components/bussiness/jump-go/code'
export default {
  name: 'AdvertisMgrForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel,
    CheckboxPlus,
    vUeditor,
    JumpGo
  },
  data () {
    return {
      width: 182,
      id: this.$route.query.id,
      uploadURL: uploadURL,
      typeOps: setTypeOps(),
      statusOps: setStatusOps(),
      form: {
        title: '',
        sort: 1,
        smallPic: '',
        content: '',
        communityIds: [],
        type: 12,
        onlineFromDate: '',
        onlineToDate: '',
        focusType: 1,
        skipData: '',
        url: '',
        sourceType: 50,
        status: 1,
        fullScreenPic: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: '',
        submitMethod: this.$route.query.id ? 'put' : 'post',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      skipTypeOps: [{
        label: '至详情页',
        value: 1
      }, {
        label: '至功能页',
        value: 2
      }, {
        label: '至外部链接',
        value: 3
      }, {
        label: '无',
        value: 5
      }],
      // 项目 multi-select begin
      communityStatusOps: setCommunityStatusOps(1),
      regionTypeOps,
      allRegionParams,
      communityStageOps: [],
      communityTypeOps: [],
      communityMultiShow: false,
      communitySearchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      communityMultiParams: {
        searchUrl: getCommunityListURL,
        headers: [
          {
            prop: 'communityName',
            label: '项目名称'
          },
          {
            prop: 'communityStatusText',
            label: '项目状态'
          },
          {
            prop: 'communityStage',
            label: '项目阶段'
          },
          {
            prop: 'provinceName',
            label: '所在省'
          },
          {
            prop: 'cityName',
            label: '所在市'
          },
          {
            prop: 'countryName',
            label: '所在区'
          },
          {
            prop: 'communityPhone',
            label: '项目电话'
          }
        ],
        responseParams: {
          id: 'id',
          name: 'communityName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        },
        handleData (data) {
          data.communityStatusText = communityStatusMap[data.communityStatus]
        }
      },
      grantExtraParams: {},
      // 项目 multi-select end
      jumpName: '点击选择要跳转的页面',
      checkParam: {
        communityList: [],
        storeIdList: [],
        socialActivityIdList: [],
        secKillIdList: [],
        knowledgeInfoIdList: [],
        discountTopicId: [],
        groupIdList: [],
        productIdList: [],
        questionIdList: [],
        questionNaireIdList: [],
        selfRuleIdList: [],
        subIdList: [],
        tableIdList: [],
        workRoomIdList: []
      },
      checkParam2: {},
      backData: {},
      backCommunitys: ''
    }
  },
  filters: {
    skipname (val) {
      try {
        let name = JSON.parse(val).name || ''
        return name
      } catch (err) {
        return ''
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editURL
      this.$refs.formPanel.getData({ focusBatchId: id })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.getProjectTypes()
      this.getRoomTypes()
    }
  },
  methods: {
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    update (data) {
      this.backData = data
      this.backCommunitys = data.communitys.map(item => item.communityName).join(',')
      this.form = {
        status: data.status,
        focusBatchId: data.focusBatchId,
        dataObject: data.title
      }
    },
    async submitBefore (data) {
      if (!await this.checkSkipCommunity()) {
        return false
      }
      if (!this.id) {
        data.communityIds = JSON.stringify(data.communityIds.map(item => item.id))
      }
      return true
    },
    changeAppType (val) {
      this.form.focusType = 1
      if (val === 14) {
        this.skipTypeOps = [{
          label: '至详情页',
          value: 1
        }, {
          label: '至外部链接',
          value: 3
        }, {
          label: '无',
          value: 5
        }]
      } else {
        this.skipTypeOps = [{
          label: '至详情页',
          value: 1
        }, {
          label: '至功能页',
          value: 2
        }, {
          label: '至外部链接',
          value: 3
        }, {
          label: '无',
          value: 5
        }]
      }
    },
    toSelectCommunity () {
      this.communityMultiShow = true
    },
    communityIdChange (list, ids) {
      this.checkParam.communityList = list.map(item => {
        return {
          communityId: item.id,
          communityName: item.text
        }
      })
      this.checkSkipCommunity()
    },
    // 校验跳转内容是否覆盖项目
    async checkSkipCommunity () {
      let params = {}
      let communityIds = this.form.communityIds ? this.form.communityIds.map(item => {
        return {
          communityId: item.id,
          communityName: item.text
        }
      }) : []
      if (this.form.focusType === 1) {
        let div = document.createElement('div')
        div.innerHTML = this.form.content
        let jumpResultList = []
        let dom = div.getElementsByClassName('jumpResult')
        for (let i = 0; i < dom.length; i++) {
          jumpResultList.push({
            pageId: dom[i].attributes.pageid.nodeValue,
            detailId: dom[i].attributes.detailid.nodeValue
          })
        }
        params = {
          communityList: communityIds,
          storeIdList: [],
          socialActivityIdList: [],
          secKillIdList: [],
          knowledgeInfoIdList: [],
          discountTopicId: [],
          groupIdList: [],
          productIdList: [],
          questionIdList: [],
          questionNaireIdList: [],
          selfRuleIdList: [],
          subIdList: [],
          tableIdList: [],
          workRoomIdList: []
        }
        jumpResultList.forEach(item => {
          let isAdd = false
          Object.keys(jumpGoCode).forEach(items => {
            if (item.pageId === jumpGoCode[items]) {
              if (item.pageType !== 3) {
                // 3是应用，使用subIdList
                isAdd = true
                params[jumpGoCodeMap[items]].push(item.detailId)
              }
            }
          })
          !isAdd && params.subIdList.push(item.detailId)
        })
      } else {
        params = {
          communityList: communityIds,
          ...this.checkParam2
        }
      }
      let res = await this.$axios.post(checkSkipCommunityURL, params)
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          let tempList = []
          res.data.forEach((item, index) => {
            tempList.push(item.communityName)
          })
          let message = `跳转内容无法覆盖项目【${tempList.join('、')}】发布之后这些项目点击将无法实现跳转，请确认是否发布`
          let isOk = await this.$confirm(message, { title: '提示' })
          if (!isOk) {
            // 不同意，就要清空所选的园区
            this.form.communityIds = []
            return false
          }
          return isOk
        } else {
          return true
        }
      }
    },
    // 插入跳转的回调
    jumpCallback (skipdata, css, html) {
      if (this.form.content.indexOf('jumpResult') === -1) {
        this.form.content = `${this.form.content}${css}${html}`
      } else {
        this.form.content = `${this.form.content}${html}`
      }

      // 处理选中的参数，选择对应的类型加入checkParam
      this.dealWithSelectJump(skipdata)
    },

    jumpFunCallback (skipdata, name) {
      if (!name) {
        return
      }
      this.jumpName = name
      this.form.skipData = JSON.stringify(skipdata)
      let checkParam = {}
      let isAdd = false
      Object.keys(jumpGoCode).forEach(item => {
        if (skipdata.pageId === jumpGoCode[item]) {
          if (skipdata.pageType !== 3) {
            // 3是应用，使用subIdList
            isAdd = true
            checkParam[jumpGoCodeMap[item]] = [skipdata.detailId]
          }
        }
      })
      !isAdd && (checkParam.subIdList = [skipdata.detailId])
      this.checkParam2 = checkParam
      // 处理选中的参数，选择对应的类型加入checkParam
      // this.dealWithSelectJump(skipdata)
    },

    // 处理跳转内容参数，以便校验跳转的项目覆盖
    dealWithSelectJump (skipdata) {
      // TOOD 处理选中的参数，选择对应的类型加入checkParam
      console.log(skipdata)
      let isAdd = false
      Object.keys(jumpGoCode).forEach(item => {
        if (skipdata.pageId === jumpGoCode[item]) {
          if (skipdata.pageType !== 3) {
            // 3是应用，使用subIdList
            isAdd = true
            this.checkParam[jumpGoCodeMap[item]].push(skipdata.detailId)
          }
        }
      })
      !isAdd && this.checkParam.subIdList.push(skipdata.detailId)
      // if (this.form.communityIds.length > 0) {
      //   this.checkSkipCommunity()
      // }
    },
    toPreview (url) {
      this.$previewBox([url], 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-div-wrapper {
  width: 100%;
  display: inline-block;

  .choose-jump-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 750px;
  }
}
</style>
